h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: none;
  font-weight: 700;
  margin-top: 40px;

  a {
    color: var(--color-foreground-primary);
  }
}

h1 {
  font-size: 32px;
  line-height: 48px;
  margin-top: 30px;
  margin-bottom: 30px;
}

h2 {
  font-size: 24px;
  line-height: 32px;
}

h3 {
  font-size: 20px;
  line-height: 28px;
}

h4,
h5,
h6 {
  font-size: 18px;
  line-height: 25px;
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.38889;
}

a {
  text-decoration: none;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: 600;
}

dfn {
  font-style: italic;
}

ins {
  background: var(--color-highlight-on-target);
  color: var(--color-foreground-primary);
  text-decoration: none;
}

mark {
  background: var(--color-highlight-on-target);
  color: var(--color-foreground-primary);
  font-style: italic;
  font-weight: 600;
}

blockquote {
  margin: 0;
  padding-left: 20px;
  line-height: 24px;
  margin-bottom: 24px;
  border-left: 4px solid var(--color-grey);
}

hr,
hr.docutils {
  display: block;
  height: 1px;
  padding: 0;
  margin: 24px 0;
  border: 0;
  border-top: 1px solid var(--color-grey);
  background-color: none;
}

.page {
  min-height: calc(100% - 80px); // substract header and footer
}

.content .article-container {
  flex-grow: 1;
}

.content-icon-container {
  margin-top: -22px;
}

.tab-content {
  padding-top: 20px;
}

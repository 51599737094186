@font-face {
  font-family: "Helvetica Now";
  src: url("../fonts/bc12c1b5-9f94-4151-86f1-774120c7e7cd.woff2")
      format("woff2"),
    url("../fonts/64773d4b-eaac-4a57-b259-c8be950bc3c4.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("../fonts/644d8155-d6f1-42bb-8c90-2c98b785b510.woff2")
      format("woff2"),
    url("../fonts/4b0db5c8-a742-4486-9214-fe8eee4ede3d.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("../fonts/effd846d-39d3-439a-a81b-14b26fd5fb7e.woff2")
      format("woff2"),
    url("../fonts/c2e2103e-7e6b-4248-b080-0100c5896596.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("../fonts/081486f6-ae1e-4fc6-8ab9-6a939e96bbdc.woff2")
      format("woff2"),
    url("../fonts/2acf5951-bff6-44e3-949f-a3fa4de57397.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

// This file contains the styling for making the content throughout the page,
// including fonts, paragraphs, headings and spacing among these elements.

body
  font-family: var(--font-stack)
pre,
code,
kbd,
samp
  font-family: var(--font-stack--monospace)

// Make fonts look slightly nicer.
body
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

// Line height from Bootstrap 4.1
article
  line-height: 1.5

//
// Headings
//
h1,
h2,
h3,
h4,
h5,
h6
  line-height: 1.25
  font-weight: bold

  border-radius: 0.5rem
  margin-top: 0.5rem
  margin-bottom: 0.5rem
  margin-left: -0.5rem
  margin-right: -0.5rem
  padding-left: 0.5rem
  padding-right: 0.5rem

  + p
    margin-top: 0

h1
  font-size: 2.5em
  margin-top: 1.75rem
  margin-bottom: 1rem
h2
  font-size: 2em
  margin-top: 1.75rem
h3
  font-size: 1.5em
h4
  font-size: 1.25em
h5
  font-size: 1.125em
h6
  font-size: 1em

small
  opacity: 75%
  font-size: 80%

// Paragraph
p
  margin-top: 0.5rem
  margin-bottom: 0.75rem

// Horizontal rules
hr.docutils
  height: 1px
  padding: 0
  margin: 2rem 0
  background-color: var(--color-background-border)
  border: 0

.centered
  text-align: center

// Links
a
  text-decoration: underline

  color: var(--color-link)
  text-decoration-color: var(--color-link-underline)

  &:hover
    color: var(--color-link--hover)
    text-decoration-color: var(--color-link-underline--hover)
  &.muted-link
    color: inherit
    &:hover
      color: var(--color-link)
      text-decoration-color: var(--color-link-underline--hover)

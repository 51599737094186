.related-information {
  font-size: 14px;
  color: var(--color-foreground-muted);
  padding-top: 14px;
  padding-right: 0;
  padding-bottom: 16px;
  padding-left: 0;

  code {
    font-size: 12px;
    color: var(--color-foreground-muted);
    padding: 0;
    border: none;
    background: none;
  }

  a {
    color: var(--color-foreground-muted);
    text-decoration: underline;
  }
}

.related-pages {
  margin-top: 30px;

  .context span {
    font-weight: 700;
  }

  .prev-page svg {
    margin-left: -2px;
  }

  .next-page svg {
    margin-right: -2px;
  }
}

.related-pages a:hover .page-info .title {
  color: var(--color-link--hover);
}

.edit-info {
  float: right;

  a:hover,
  a:active,
  a:focus {
    color: var(--color-foreground-primary);
  }
}

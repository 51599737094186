html {
  scroll-behavior: smooth;
}

@import "./fonts";
@import "./header";
@import "./footer";
@import "./content";
@import "./sidebar";
@import "./breadcrumbs";
@import "./helpers";

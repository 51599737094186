// This file stylizes the API documentation (stuff generated by autodoc). It's
// deeply nested due to how autodoc structures the HTML without enough classes
// to select the relevant items.

// API docs!
dl[class]:not(.option-list):not(.field-list):not(.footnote):not(.glossary):not(.simple)
  // Tweak the spacing of all the things!
  dd
    margin-left: 2rem
    > :first-child
      margin-top: 0.125rem
    > :last-child
      margin-bottom: 0.75rem

  // This is used for the arguments
  .field-list
    margin-bottom: 0.75rem

    // "Headings" (like "Parameters" and "Return")
    > dt
      text-transform: uppercase
      font-size: var(--font-size--small)

    dd:empty
      margin-bottom: 0.5rem
    dd > ul
      margin-left: -1.2rem
      > li
        > p:nth-child(2)
          margin-top: 0
        // When the last-empty-paragraph follows a paragraph, it doesn't need
        // to augument the existing spacing.
        > p + p:last-child:empty
          margin-top: 0
          margin-bottom: 0

  // Colorize the elements
  > dt
    color: var(--color-api-overall)

.sig:not(.sig-inline)
  font-weight: bold

  font-size: var(--api-font-size)
  font-family: var(--font-stack--monospace)

  margin-left: -0.25rem
  margin-right: -0.25rem
  padding-top: 0.25rem
  padding-bottom: 0.25rem
  padding-right: 0.5rem

  // These are intentionally em, to properly match the font size.
  padding-left: 3em
  text-indent: -2.5em

  border-radius: 0.25rem

  background: var(--color-api-background)
  transition: background 100ms ease-out

  &:hover
    background: #{$black}

  // adjust the size of the [source] link on the right.
  a.reference
    .viewcode-link
      font-weight: normal
      width: 3.5rem

  // Break words when they're too long
  span.pre
    overflow-wrap: anywhere

em.property
  font-style: normal
  &:first-child
    color: var(--color-api-keyword)
.sig-name
  color: $primary
.sig-prename
  font-weight: normal
  color: $white
.sig-paren
  color: var(--color-api-paren)
.sig-param
  font-style: normal

.versionmodified
  font-style: italic
div.versionadded, div.versionchanged, div.deprecated
  p
    margin-top: 0.125rem
    margin-bottom: 0.125rem

// Align the [docs] and [source] to the right.
.viewcode-link, .viewcode-back
  float: right
  text-align: right

// This file contains styles for managing print media.

////////////////////////////////////////////////////////////////////////////////
// Hide elements not relevant to print media.
////////////////////////////////////////////////////////////////////////////////
@media print
  // Hide icon container.
  .content-icon-container
    display: none !important

  // Hide showing header links if hovering over when printing.
  .headerlink
    display: none !important

  // Hide mobile header.
  .mobile-header
    display: none !important

  // Hide navigation links.
  .related-pages
    display: none !important

////////////////////////////////////////////////////////////////////////////////
// Tweaks related to decolorization.
////////////////////////////////////////////////////////////////////////////////
@media print
  // Apply a border around code which no longer have a color background.
  .highlight
    border: 0.1pt solid var(--color-foreground-border)

////////////////////////////////////////////////////////////////////////////////
// Avoid page break in some relevant cases.
////////////////////////////////////////////////////////////////////////////////
@media print
  ul, ol, dl, a, table, pre, blockquote
    page-break-inside: avoid

  h1, h2, h3, h4, h5, h6, img, figure, caption
    page-break-inside: avoid
    page-break-after: avoid

  ul, ol, dl
    page-break-before: avoid

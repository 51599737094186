@import "../variables";

.divio-header {
  position: relative;
  z-index: 11;
  border-bottom: 1px solid var(--color-background-border);
}

.divio-header__inner {
  box-sizing: border-box;
  min-height: 76px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 21/14;
  width: 100%;
  position: relative;
  z-index: 200;

  @media (min-width: 1440px) {
    width: 1440px;
  }

  @media (min-width: 1072px) {
    justify-content: flex-start;
  }
}

.divio-header__logo {
  position: relative;
  z-index: 201;
  flex-shrink: 0;
  margin-top: 4px;

  > a {
    color: var(--color-foreground-primary);

    &:hover,
    &:active,
    &:focus {
      color: var(--color-link--hover);
    }
  }

  @media (max-width: 460px) {
    margin: 0 auto;
  }
}

.divio-header__claim {
  font-size: 21px;
  line-height: 24px;
  padding-left: 50px;

  @media (max-width: 1072px) {
    display: none;
  }
}

// these were previously embedded inline styles into the documentation
// place additional ones in here

// column layouts

@media only screen and (min-width: 1000px),
  only screen and (min-width: 500px) and (max-width: 768px) {
  .column {
    float: left;
    width: 45%;
    margin-right: 5%;
    margin-bottom: 30px;

    h2 {
      margin-top: 0;
    }
  }
}

.column img {
  border: 1px solid var(--color-foreground-primary);
}

.clearfix {
  clear: both;
}

// visuals
.main-visual {
  margin-bottom: 0 !important;
}

// responsive handling
.embed-responsive {
  position: relative;
  width: 100%;
}
.embed-responsive:before {
  display: block;
  content: "";
}
.embed-responsive-item,
.embed-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}

.mt-0 {
  margin-top: 0;
}

.btn {
  cursor: pointer;
  display: inline-block;
  color: var(--color-background-primary);
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  text-align: center;
  padding: 10px 20px;
  border-radius: 3px;
  background-color: var(--color-foreground-primary);
  transition: all 0.15s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: var(--color-background-primary);
    background-color: var(--color-brand-primary);
  }
}

.btn + .btn {
  margin: 5px 0;
}

.btn-small {
  font-size: 16px;
  padding: 5px 10px;
}

.custon-headings {
  margin-bottom: -40px;

  h2 {
    margin-top: -20px;
  }

  h3 {
    margin-top: 0;
  }
}

.breadcrumbs {
  margin-bottom: -10px;
}

.wy-breadcrumbs {
  padding: ($content-spacing + 5) 0 0;
  margin: 0;
}

.wy-breadcrumbs li {
  display: inline-block;
}

.wy-breadcrumbs li.wy-breadcrumbs-aside {
  float: right;
}

.wy-breadcrumbs li a {
  display: inline-block;
  padding: 5px 0 5px;
}

.wy-breadcrumbs li a:first-child {
  padding-left: 0;
}

.wy-breadcrumbs li code,
.wy-breadcrumbs li .rst-content tt,
.rst-content .wy-breadcrumbs li tt {
  padding: 5px;
  border: none;
  background: none;
}

.wy-breadcrumbs li code.literal,
.wy-breadcrumbs li .rst-content tt.literal,
.rst-content .wy-breadcrumbs li tt.literal {
  color: var(--color-foreground-primary);
}

.wy-breadcrumbs-extra {
  margin-bottom: 0;
  color: var(--color-background-border);
  font-size: 80%;
  display: inline-block;
}

@media screen and (max-width: 480px) {
  .wy-breadcrumbs-extra {
    display: none;
  }
  .wy-breadcrumbs li.wy-breadcrumbs-aside {
    display: none;
  }
}

@media print {
  .wy-breadcrumbs li.wy-breadcrumbs-aside {
    display: none;
  }
}

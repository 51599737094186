.sidebar-brand {
  display: none;
}

.sidebar-tree {
  padding: 0 0 10px;
  margin-bottom: 60px; // versions

  li > ul {
    font-size: 14px;
  }

  .toctree-l1 > label .icon {
    color: var(--color-foreground-secondary);
  }
}

// search
.sidebar-search-container {
  margin-top: $content-spacing;
  background: var(--color-background-primary);
}

.sidebar-search {
  border: 1px solid var(--color-background-border);
  border-radius: 2px 0 0 2px;

  @media (min-width: 1072px) {
    border-right: 1px solid var(--color-background-primary);
    position: relative;
    left: 1px;
    top: -1px;
  }
}

.toc-drawer {
  z-index: 1000;
}

.theme-toggle-header {
  @media (max-width: 1071px) {
    position: absolute;
    right: 30px;
  }
}

body > .injected {
  // hide the rtd banner as we inject it ourselfes
  display: none !important;
}
